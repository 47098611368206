import { Routes, Route } from 'react-router-dom';
import { AddPaciente } from './forms/AddPaciente';
import { Appointment } from './forms/Appointment';

function App() {
  return (
    <div className='min-h-screen bg-gradient-to-b from-slate-200 via-slate-300 to-slate-400'>
      <Routes>
          <Route path="/" element={ <Appointment/>}/>
          <Route path="/nuevo" element={<AddPaciente />} />
      </Routes>
    </div>
  );
}

export default App;
