const baseURL = 'https://endpoint.centrooftalmorecoleta.net/api/';

export const URLS = {

    PACIENTE_POST_URL: baseURL + 'paciente/createPaciente',
    GET_ALL_PACIENTES: baseURL + 'paciente/all',
    GET_PACIENTE_BY_DNI: baseURL + 'paciente/dni/',
    GET_ALL_PROFESIONALES: baseURL + 'profesional/allActivos',
    GET_CP_URL: baseURL + 'cp/all',
    GET_TURNO_BY_DATE_PROFESSIONAL_LIBRE: baseURL + 'turno/libre/turnosDisponible',
    GET_TURNO_BY_PROFESSIONAL_LIBRE: baseURL + 'turno/libre/turnosProfesional',
    GET_TURNO_BY_PACIENTE: baseURL + 'turno/datePaciente/',
    GET_OBRAS_SOCIALES: baseURL + 'obrasocial/allActivas',
    TURN_PUT_URL: baseURL + 'turno/update',
};

export const CONFIG = {
    headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer 5|slaDQyCA1Jf7ViryKhNsd55qx8aiwjwYB4udrWhx'
    }
};
