import { getTurnsByProfessionalLibre } from '../services/data.service';

export const getSchedules = async (prof, obraSocial) => {
    try {
        const scheduleService = await getTurnsByProfessionalLibre(prof[0].codigo, obraSocial);
        if (scheduleService !== undefined) {
            return scheduleService;
        } else {
            return [];
        }
    } catch (err) {
        console.error(err);
        return [];
    }
};

export const formattedPaciente = (paciente) => {
    return {
        id: paciente.id,
        data: {
            documento: paciente.NumDocumento,
            fechaNacimiento: paciente.FechaNacimiento && paciente.FechaNacimiento.split('-').reverse().join('/'),
            domicilio: paciente.Direccion,
            localidad: paciente.ciudad,
            obraSocial: paciente.Plan,
            paciente: paciente.Nombre,
            sexo: paciente.Genero,
            telefono: paciente.Telefono
        }
    };
};
