import Autocomplete from '@mui/material/Autocomplete';
import { React, useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import { Box, FormControl } from '@mui/material';
import { CSS_INPUT } from '../constants/const_tailwind';

export function AutoComplete (pars) {
    const [opt, setOpt] = useState('');
    const { data, onChange, ide, initialValue, clear } = pars;

    useEffect(() => {
        if (typeof initialValue !== 'undefined') {
            setOpt(initialValue);
        }
    }, [initialValue]);

    useEffect(() => {
        if (clear) {
            setOpt('');
        }
    }, [clear]);

    const handleChange = (event, value) => {
        setOpt(value);
        if (onChange !== undefined) {
            const iddata = data.filter(d => d.text === value);
            if (value !== null) {
                iddata[0].name = ide;
            }
            onChange(iddata, value);
        }
    };

    return (
        <div className={'flex flex-col mx-3'}>
            <Box>
                <FormControl fullWidth size="small">
                    <label className={ CSS_INPUT.DEFAULT_THEME_LABEL}>{pars.label}</label>
                    <Autocomplete
                        className=" max-h-44 bg-tradewind-50  rounded p-0"
                        disablePortal
                        noOptionsText="No hay resultados"
                        size="small"
                        autoHighlight={true}
                        value={opt}
                        disabled={pars.disabled}
                        id={ide}
                        options={data ? data.map((d) => d.text) : []}
                        onChange={handleChange}
                        renderInput={(params) => (
                            <TextField {...params} />
                        )}
                    />
                </FormControl>
            </Box>
        </div>
    );
}
