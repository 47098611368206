import * as React from 'react';
import { useState, useEffect } from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { CSS_INPUT } from '../constants/const_tailwind';
import { useMediaQuery } from '@mui/material';

export default function InputSelect ({ estilo, data, pars, initialValue, onChange }) {
    const [opt, setOpt] = useState('');
    const [opts, setOpts] = useState(data || []);
    const isMobile = useMediaQuery('(max-width:600px)');
    useEffect(() => {
        if (typeof initialValue !== 'undefined') {
            setOpt(initialValue);
        }
    }, [initialValue]);

    useEffect(() => {
        setOpts(data);
    }, [data]);
    const handleChange = (event) => {
        setOpt(event.target.value);
        if (onChange !== undefined) {
            const iddata = data.filter(d => d.text === event.target.value);
            event.target.name = pars.ide;
            onChange(event, iddata);
        }
    };
    const disabledSelectStyle = {
        backgroundColor: '#f0f0f0',
        borderRadius: '4px',
        ...estilo,
        ...(isMobile && {
            maxWidth: '320px',
        })
    };
    return (
        <div className={'flex flex-col mx-3'}>
            <label className={ CSS_INPUT.DEFAULT_THEME_LABEL}>{pars.label}</label>
            <FormControl sx={{ ...(disabledSelectStyle), '&[aria-disabled="true"]': disabledSelectStyle }}>
                <Select
                    id={pars.ide}
                    key={pars.ide}
                    disabled={pars.disabled}
                    value={opt}
                    onChange={handleChange}
                    fullWidth
                    sx={{ 
                        '&.Mui-disabled': {
                            backgroundColor: '#eaeaea !important',
                        },
                    }}
                    className={estilo ? 'max-h-10 bg-white ' + estilo : 'max-h-10 bg-white'}
                >
                    {opts && opts.length > 0
                        ? opts.map(op => (
                            <MenuItem key={op.id} value={op.text} disabled={!!op.disabled}>{op.text}</MenuItem>
                        )
                        )
                        : <MenuItem disabled> No hay resultados</MenuItem>
                    }
                </Select>
            </FormControl>
        </div>
    );
}
