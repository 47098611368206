import { addPacienteConstants } from '../constants/constants';
import { postPacienteService } from '../services/data.service';
import { errorPopup } from '../components/popup';
import { errors } from '../constants/constants';

export const constante = (constant) => {
    return addPacienteConstants[constant];
};

export function getFormattedPatient (userResult) {

    return {
        id: userResult.id,
        Nombre: userResult.Nombre,
        FechaNacimiento: userResult.FechaNacimiento,
        Genero: userResult.Genero,
        Direccion: userResult.Direccion,
        CodPos: userResult.CodPos,
        ciudad: userResult.ciudad,
        Telefono: userResult.Telefono,
        Mail: userResult.Mail,
        tipoDocumento: 1,
        NumDocumento: userResult.NumDocumento,
        Empres: userResult.Empres,
        Plan: userResult.Plan,
        OSSecundaria: userResult.OSSecundaria,
        NumAfiliado: userResult.NumAfiliado,
        MedCabecera: userResult.MedCabeceraNombre,
        MedCabeceraCod: userResult.MedCabecera,
        UltimaVisita: userResult.UltimaVisita,
        Antecedentes: userResult.Antecedentes
    };
}

export async function postPaciente (paciente) {
    let inputToValidate = '';
    const validatedInputs = Object.keys(paciente).every((i) => {
        const needsValidation = constante('VALID_INPUTS_PACIENT').includes(i);
        inputToValidate = i;
        return (!needsValidation || (needsValidation && paciente[i] !== ''));
    });
    if (validatedInputs) {
        const response = await postPacienteService(paciente);
        return response;
    } else {
        errorPopup({
            title: 'Debe ingresar los datos obligatorios.',
            text: errors[inputToValidate]
        });
        return false;
    }
}
