import React, { useState, useEffect } from 'react';
import InputSelect from '../components/inputSelect';
import { useProfessionals } from '../hooks/useProfesional';
import { emptyTurn } from '../constants/constants';
import Boton from '../components/boton';
import logo from '../images/logo_recoleta.webp';
import { TextArea } from '../components/inputTextArea';
import { InputText } from '../components/inputText';
import { getPacienteByDNI } from '../services/data.service';
import { warningPopup } from '../components/popup';
import { useNavigate, useLocation } from 'react-router-dom';
import { getSchedules } from '../logic/turnos';
import { assembleDoc, removeUndefinedInputs } from '../logic/utils';
import { saveTurn } from '../services/data.service';
import { useObraSociales } from '../hooks/useObraSociales';
import { CircularProgress } from '@mui/material';
import jsPDF from 'jspdf';

export function Appointment () {
    const navigate = useNavigate();
    const location = useLocation();
    const { obrasociales } = useObraSociales();
    const { professionals } = useProfessionals();
    const [turn, setTurn] = useState({ ...emptyTurn, dni: '' });
    const [scheduleText, setScheduleText] = useState('');
    const [patientName, setPatientName] = useState('');
    const [schedule, setSchedule] = useState([]);
    const [disabledButton, setDisabledButton] = useState(false);
    const [disabledForm, setDisabledForm] = useState(true);
    const [disabledFormObraSocial, setDisabledFormObraSocial] = useState(true);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if(location.state){
            setPatientName(location.state.nombre);
            let newTurn = { paciente_id: location.state.idpaciente, dni: location.state.dni, obra_social_text: location.state.empres };
            newTurn = removeUndefinedInputs(newTurn);
            setTurn({ ...turn, ...newTurn });
            setDisabledFormObraSocial(false);
            window.history.replaceState({}, '');
        }

        setTimeout(() => {
            const enable = turn.professional !== -1 && turn.paciente_id !== '' && turn.horario_id !== '';
            setDisabledButton(!enable);
        }, 100);
    }, [turn, location.state]);

    const handleChangeSelector = (event, opt) => {
        const { name } = event.target;
        if (name === 'horario_id') {
            setTurn({ ...turn, horario_id: opt[0].id, fecha: opt[0].text.split('a')[0].trim() });
            setScheduleText(opt[0].text);
        } else if (name === 'professional') {
            handleSchedules(opt, turn.obra_social);
            setTurn({ ...turn, professional: opt });
        } else {
            setTurn({ 
                ...turn, 
                obra_social_text: opt[0].text,
                obra_social: opt[0].codigo,
                obra_social_mensaje: opt[0].mensaje
            });
            setDisabledForm(false);
        }
    };

    const handleSchedules = async (prof, obraSocial) => {
        const response = await getSchedules(prof, obraSocial);
        if (response) {
            setSchedule(response);
        }
    };
    const saveNewTurn = () => {
        warningPopup({
            title: 'Está a punto de asignar un nuevo turno.',
            text: 'Está seguro de que quiere continuar?'
        })
            .then(async (response) => {
                if (response.isConfirmed) {
                    if (await saveTurn(turn)) {
                        downloadPDF();
                    }
                }
            });
    };

    const handleChangeDoc = async (event) => {
        const value = event.target.value;
        setTurn({ ...turn, dni: value });
        if (value.length === 8) {
            setLoading(true);
            const paciente = await getPacienteByDNI(value);
            if (paciente) {
                setDisabledFormObraSocial(false);
                setTurn({ ...turn, paciente_id: paciente.id, dni: value, Empres: paciente.Empres });
                setPatientName(paciente.nombre);
            }
            else{
                warningPopup({ title: 'Paciente no encontrado', text: 'No se encuentra en nuestros registros, quiere darse de alta?', confirmButtonText: 'Darse de alta' })
                .then((response) => {
                    if(response.isConfirmed){
                        navigate('/nuevo', { state: { dni: value } });
                    }
                });
            }
            setLoading(false);
        }
        else{
            setTurn({ ...turn, dni: value });
        }
    };

    const downloadPDF = async () => {
        const pac = await getPacienteByDNI(turn.dni);
        let doc = new jsPDF('p', 'mm', 'a4');
        doc = assembleDoc(doc, pac, turn,scheduleText,turn.obra_social_mensaje);
        doc.save('turno.pdf');
    }

    return (
        <div className='md:grid place-content-center md:h-screen'>
            <div class="rounded overflow-hidden md:grid md:grid-cols-2 md:shadow-2xl px-16 py-10 gap-8">
                <aside className='md:mt-24 md:pt-0 py-5'>
                    <div className='border-b-2 border-gray-300/90'>
                        <img src={logo} alt="profesional" className='m-auto' />
                        <h1 className='text-3xl flex justify-center my-5 text-principal font-bold'>Solicitar turno</h1>
                    </div>
                    <p className='text-center md:text-left'> Para más información, consulte nuestro <a target='_blank' rel='noreferrer' href="https://centrooftalmorecoleta.net/" className='text-principal font-bold hover:underline'>sitio web</a>
                    </p>
                </aside>
                <aside class="grid gap-4 md:mt-2 place-content-center w-full">
                    <div>
                        <InputText disabled={loading} ide='paciente_id' label={'Ingrese su DNI - con 0 adelante de ser necesario'} value={turn.dni} onChange={handleChangeDoc} keyUpType={'only-number'}/>
                        {patientName && !loading && <p className='ms-5 mt-1 text-tradewind-800 text-sm'>{'Bienvenido nuevamente ' + patientName + '!'}</p>}
                        {
                            loading &&
                                <div className='flex gap-2 ms-5 mt-1'>
                                    <CircularProgress size={20} className='text-principal'/>
                                    <p className='font-bold text-sm'>Cargando...</p>
                                </div>
                        }
                    </div>
                    <InputSelect estilo={{ maxWidth: '360px' }} onChange={handleChangeSelector} initialValue={turn.obra_social_text} pars={{ ide: 'obra_social', label: 'Seleccione una obra social', disabled: disabledFormObraSocial }} data={obrasociales} disabled={disabledForm}/>
                    <InputSelect onChange={handleChangeSelector} initialValue={''} pars={{ ide: 'professional', label: 'Seleccione un profesional', disabled: disabledForm }} data={professionals} disabled={disabledForm}/>
                    <InputSelect onChange={handleChangeSelector} pars={{ disabled: disabledForm, ide: 'horario_id', label: 'Horarios disponibles' }} data={schedule.length > 0 ? schedule : setSchedule([{ id: -1, text: 'No hay turnos disponibles', disabled: true }])}/>
                    <TextArea disabled={disabledForm} label="Observaciones" ide="observ" value={turn.observaciones} />
                    <div className="flex gap-2 md:justify-end justify-center md:m-3 ">
                        <Boton className="justify-self-end" estilo={"m-0"} ide="saveTurn" texto="Reservar turno" color={'bg-tradewind-700 hover:bg-tradewind-600 active:bg-tradewind-500'} onClick={saveNewTurn} disabled={disabledButton}></Boton>
                    </div>
                </aside>
            </div>
        </div>
    );
}
