import React from 'react';
import { CSS_INPUT } from '../constants/const_tailwind';

export function InputText ({ ide, className, label, type, onChange, value, keyUpType, placeholder, maxLength, disabled }) {
    const inputClass = 'p-2 rounded-md border focus:outline-none text-black ';
    const constante = (constant) => {
        return CSS_INPUT[constant];
    };

    const handleOnlyNumber = (e) => {
        if (e.which !== 8 && e.which !== 0 && (e.which < 48 || e.which > 57)) {
            e.preventDefault();
        }
    };
    const handleOnlyText = (e) => {
        if (e.which >= 48 && e.which <= 57) {
            e.preventDefault();
        }
    };

    if (keyUpType !== undefined) {
        switch (keyUpType) {
        case 'only-text': {
            keyUpType = handleOnlyText;
            break;
        }
        case 'only-number': {
            keyUpType = handleOnlyNumber;
            break;
        }
        default: {
            keyUpType = undefined;
        }
        }
    }

    return (
        <div className={'flex flex-col mx-3 ' + className}>
            <label className={ constante('DEFAULT_THEME_LABEL')} htmlFor={ide}>{label}</label>
            <input disabled={disabled} maxLength={maxLength} placeholder={placeholder} value={value} onKeyPress={keyUpType} onChange={onChange} type={ type || 'text' } id={ide} name={ide} className={inputClass + ( constante('DEFAULT_THEME_INPUT'))} autoComplete="off"/>
        </div>
    );
}
