import { get, post, put } from './api.service.js';
import { URLS } from '../constants/const_services';
import { errorPopup, successPopup } from '../components/popup';
import { transformDateInvert } from '../logic/utils.js';
/** *********************** POST  ************************/

export async function postPacienteService (data) {
    if (!data) return false;
    deleteEmptyFields(data);
    try{
        const response = await post(URLS.PACIENTE_POST_URL, data);
        if(response.data && response.data.status){
            successPopup();
            return response.data.paciente;
        }
    } catch (error) {
        console.log(error);
        handleError(error);
        return false;
    }
    
}

export async function saveTurn (turn) {
    turn.fecha = turn.fecha.split("/").reverse().join("-");
    try {
        const checkTurnExist = await checkTurnPatient(turn.paciente_id);
        if (checkTurnExist) {
            errorPopup({
                title: 'Error',
                text: 'El paciente ya tiene un turno asignado.'
            });
            return;
        }
        delete turn.obra_social_text;
        const response = await put(URLS.TURN_PUT_URL + '/' + turn.horario_id, turn);
        if (response.data.status) {
            console.log(turn.obras_social_mensaje);
            if(turn.obra_social_mensaje){
                successPopup(
                    "Turno asignado correctamente",
                    "Recuerde: " + turn.obra_social_mensaje
                );
            }
            else{
                successPopup("Turno asignado correctamente");
            }
            return true;
        }
    } catch (error) {
        console.log(error);
        handleError(error);
        return false;
    }
}

/** *********************** GET ************************/
export async function getLocalidadesService () {
    try {
        const json = await get(URLS.GET_CP_URL);
        const localidades = sortByAlphabetic(json.data.codigos_postales
            .map((cp) => ({ ide: cp.id, text: cp.ciudad + ' - ' + cp.provincia, codigo: cp.codigo })));
        return localidades;
    } catch (error) {
        console.log(error);
        return [];
    }
}

export async function getTurnsByDateProfessionalLibre (date, profesional) {
    try {
        const response = await get(URLS.GET_TURNO_BY_DATE_PROFESSIONAL_LIBRE + '?fecha=' + date + '&prof_cod=' + profesional);
        return (
            response.data.turnos.map((t) => ({ id: t.id, text: t.hora }))
        );
    } catch (err) {
        if (err.response.status === 404 && err.response.data.error.includes('No se encontraron horarios disponibles')) {
            return [{ id: -1, text: 'No se encontraron horarios disponibles' }];
        } else {
            console.error(err);
        }
    }
}

export async function getTurnsByProfessionalLibre (profesional, obraSocial) {
    try {
        const response = await get(URLS.GET_TURNO_BY_PROFESSIONAL_LIBRE + '?prof_cod=' + profesional + '&obraSocial=' + obraSocial);
        return (
            response.data.turnos.map((t) => ({ id: t.id, text: transformDateInvert(t.fecha) + ' a las ' +t.hora }))
        );
    } catch (err) {
        if (err.response.status === 404 && err.response.data.error.includes('No se encontraron horarios disponibles')) {
            return [{ id: -1, text: 'No se encontraron horarios disponibles' }];
        } else {
            console.error(err);
        }
    }
}

export async function getAllObrasociales () {
    try {
        const json = await get(URLS.GET_OBRAS_SOCIALES);
        return json.data.obras_sociales.map((os) => ({ id: os.id, codigo: os.codigo, text: os.descripcion, mensaje: os.mensaje1 }));
    } catch (error) {
        console.log(error);
        return [];
    }
}
// eslint-disable-next-line no-unused-vars
export async function getAllProfesionalesService () {
    try {
        const json = await get(URLS.GET_ALL_PROFESIONALES);
        return sortByAlphabetic(json.data.profesionales.map((user) => ({ id: user.id, text: user.Apellido + ' ' + user.Nombre, codigo: user.Codigo })));
    } catch (error) {
        console.log(error);
        return [];
    }
}

export async function getPacienteByDNI (dni) {
    try {
        const json = await get(URLS.GET_PACIENTE_BY_DNI + dni);
        const paciente = json.data.paciente;
        return { 
            id: paciente.id, 
            nombre: paciente.Nombre, 
            Empres: paciente.Empres, 
            FechaNacimiento: paciente.FechaNacimiento, 
            Genero: paciente.Genero,
            Nombre: paciente.Nombre
        };
    } catch (error) {
        console.log(error);
        return false;
    }
}

export async function getTurnByPaciente (id) {
    try {
        const json = await get(URLS.GET_TURNO_BY_PACIENTE + id);
        return json.data;
    } catch (error) {
        console.log(error);
        return [];
    }
}

/** *********************** PUT ************************ */
export async function putTurn (id, data) {
    try {
        const response = await put(URLS.TURN_PUT_URL + '/' + id, data);
        return response.data.status;
    } catch (error) {
        console.log(error);
        return false;
    }
}

/** ******************UTILS *****************/

function sortByAlphabetic (list) {
    return list.sort((a, b) => {
        if (a.text > b.text) {
            return 1;
        }
        if (a.text < b.text) {
            return -1;
        }
        return 0;
    });
}

export async function turnsWithPatients (turns) {
    const formattedTurns = [];
    for (const turn of turns) {
        formattedTurns.push({
            id: turn.id,
            hora: turn.hora,
            profesional: turn.profesional ? turn.profesional.Apellido + ' ' + turn.profesional.Nombre : null,
            modify_paciente: turn.paciente ? turn.paciente : null,
            paciente: turn.paciente ? turn.paciente.Nombre : '-',
            paciente_id: turn.paciente ? turn.paciente.id : '-',
            presente: turn.presente,
            primeraVisita: turn.primeraVisita,
            atendido: turn.atendido,
            obraSocial: turn.paciente ? turn.paciente.Empres : '-',
            telefono: turn.paciente ? turn.paciente.Telefono : '-',
            observaciones: turn.observ
        });
    }
    return formattedTurns;
}

function deleteEmptyFields (data) {
    Object.keys(data).forEach((key) => {
        if (data[key] === '' || data[key] === null || data[key] === undefined) {
            delete data[key];
        }
    });
    data.Nombre = (data.Apellido + ' ' + data.Nombre).toUpperCase();
    delete data.Apellido;
    delete data.PlanText;
    delete data.Plan2Text;
}

function handleError (error) {
    const errors = error.response.data.error;
    if (errors && typeof errors !== 'string') {
        let errorHtml = '<ul>';
        Object.values(error.response.data.error).forEach((e) => {
            errorHtml += `<li>${e}</li>`;
        });
        errorHtml += '</ul>';
        errorPopup({ title: 'Error', html: errorHtml });
    }
    if (errors && typeof errors === 'string') {
        errorPopup({ title: 'Error', text: errors });
    }
}

async function checkTurnPatient(idPaciente) {
    try {
        const turnos = await getTurnByPaciente(idPaciente);
        if (!turnos.status) {
            return false;
        }
        const actualDate = new Date();
        const response = turnos.turnos.some((turn) => actualDate <= new Date(turn.fecha));
        return response;
    } catch (error) {
        console.log(error);
        return true;
    }
}
