import imgPDF from '../images/clinica_pdf.png';

export const transformDate = (date) => {
    if (date === '' || date === null) return '';
    const dateFormatRegex = /^\d{4}-\d{2}-\d{2}$/;
    if (dateFormatRegex.test(date)) return date;
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    const d = new Date(date).toLocaleDateString('es-AR', options);
    return d.split('/').reverse().join('-');
};

export const transformDateInvert = (date) => {
    if (date === '') return '';
    const dateFormatRegex = /^\d{4}-\d{2}-\d{2}$/;
    if (!dateFormatRegex.test(date)) return date;
    const [year, month, day] = date.split('-');
    return `${day}/${month}/${year}`;
};


export function calculateAge (bornDate) {
    if (!bornDate) return;
    const today = new Date();
    const birthDate = new Date(bornDate.split('/').reverse('-'));
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}

export function assembleDoc (doc, pac, turn,scheduleText, osMessage) {
        doc.setFillColor(255, 255, 255);
        doc.rect(0, 0, 210, 297, 'F'); 
        const img = new Image();
        img.src = imgPDF;
        doc.addImage(img, 'PNG', 10, 10, 100, 30);
        doc.setFontSize(12);

        // Personal information box - Name
        doc.setDrawColor(15, 15, 15); // setea color de las lineas del rectangulo
        doc.setLineWidth(0.2); // ancho de la linea
        doc.rect(10, 70, 190, 20); // genera el rectangulo
        doc.setFontSize(12);
        
        doc.rect(10, 90, 190, 30);        
        doc.text(pac.FechaNacimiento ? pac.FechaNacimiento.split('-').reverse().join('/') : '-', 39, 102);
        doc.text(pac.FechaNacimiento ? calculateAge(pac.FechaNacimiento).toString() : '-', 28, 112);
        doc.text(pac.Genero, 85, 102);
        doc.text(turn.obra_social_text ? turn.obra_social_text: '-', 96, 112);
        
        // Cabeceras en negrita
        doc.setFont('helvetica', 'bold');
        doc.setFontSize(16);
        doc.text(pac.Nombre, 15, 82);
        doc.setFontSize(12);
        doc.text('Fecha nac.:', 15, 102);
        doc.text('Edad:', 15, 112);
        doc.text('Sexo:', 70, 102);
        doc.text('Obra social:', 70, 112);
        doc.text(`DNI:`, 130, 102);
        doc.text(`Día del turno:`, 10, 140);
        doc.text('Profesional:', 10, 150);
        doc.text('Horario:', 10, 160);
        if(osMessage) doc.text('RECUERDE:', 10, 170);
        // Datos del turno
        doc.setFont('helvetica', 'normal');
        doc.text(turn.dni.toString(), 140, 102);
        doc.text(turn.fecha, 38, 140);
        doc.text(turn.professional[0].text, 36, 150);
        doc.text(scheduleText.split('s')[1].trim(), 27, 160);
        if(osMessage) doc.text(osMessage, 37, 170);
        // Footer
        doc.setFont('helvetica', 'bold');
        doc.text('Contacto', 10, 260);
        doc.text('Horarios de atención', 120, 260);
        doc.setFont('helvetica', 'normal');
        doc.setFontSize(10);
        doc.text('N° de teléfono:0342 4 531212', 14, 270);
        doc.text('Dirección: San Jerónimo 3162, Santa Fe, 3000', 14, 280);
        doc.text('Lunes a Viernes de 9.00 a 20.00hs.', 124, 270);

        return doc;
}

export function removeUndefinedInputs(obj) {
    Object.keys(obj).forEach(key => obj[key] === undefined && delete obj[key]);
    return obj;
}