import axios from 'axios';
import { CONFIG } from '../constants/const_services';

// CONFIG.headers.Authorization = localStorage.getItem('user') !== null ? 'Bearer ' + (JSON.parse(localStorage.getItem('user'))).token : '';

export function get (url, needConfig = true) {
    return needConfig ? axios.get(url, CONFIG) : axios.get(url);
}

export function post (url, data, needConfig = true) {
    if (needConfig) {
        data.usuario = 'APPTURNOS';
    }
    return needConfig ? axios.post(url, data, CONFIG) : axios.post(url, data);
}

export function put (url, data, needConfig = true, multiple = false) {
    if (needConfig && !multiple) {
        data.usuario = 'APPTURNOS';
    }
    if (multiple) {
        data = data.map((d) => ({ ...d, usuario: (JSON.parse(localStorage.getItem('user'))).userName }));
    }
    return needConfig ? axios.put(url, data, CONFIG) : axios.put(url, data);
}

export function deleteService (url, data, needConfig = true) {
    return needConfig ? axios.delete(url, CONFIG) : axios.delete(url);
}

export function setAuthHeader (token) {
    CONFIG.headers.Authorization = 'Bearer ' + token;
}
