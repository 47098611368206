import React, { useEffect, useState } from 'react';
import { InputText } from '../components/inputText';
import { TextArea } from '../components/inputTextArea';
import Boton from '../components/boton';
import { SEXO, Popups } from '../constants/constants';
import InputSelect from '../components/inputSelect';
import { constante, postPaciente } from '../logic/paciente';
import { warningPopup } from '../components/popup';
import { AutoComplete } from '../components/autocomplete';
import InputDatePicker from '../components/inputDatePicker';
import { useLocalidades } from '../hooks/useLocalidades';
import { useObraSociales } from '../hooks/useObraSociales';
import { useLocation, useNavigate } from 'react-router-dom';

export function AddPaciente () {
    const location = useLocation();
    const navigate = useNavigate();
    const [paciente, setPaciente] = useState(constante('emptyInputs'));
    const [clear, setClear] = useState(false);
    const { localidades } = useLocalidades();
    const { obrasociales } = useObraSociales();

    useEffect(() => {
        if (location.state) {
            setPaciente((prevPaciente) => ({
                ...prevPaciente,
                NumDocumento: location.state.dni
            }));
            window.history.replaceState({}, '');
        }
    }, [location]);

    /* ******************* Handlers **************** */
    const handleSave = () => {
        warningPopup(Popups.OPCIONES_POPUP_PACIENTE)
            .then((response) => {
                if (response.isConfirmed) {
                    navigateAppointment();
                }
            });
    };

    const navigateAppointment = async () => {
        const response = await postPaciente(paciente);
        if(response){
            navigate('/', { state: { 
                dni: paciente.NumDocumento,
                idpaciente: response.id,
                nombre: response.Nombre,
                Plan: paciente.Plan
            } });
            handleClearForm();
        }
    }

    const handleChange = (e, opt) => {
        const { name, value } = e.target;
        if (name === 'CodPos') {
            console.log(opt);
            setPaciente((prevPaciente) => ({
                ...prevPaciente,
                CodPos: opt[0].codigo + '',
                ciudad: opt[0].text
            }));
            return;
        }
        setPaciente((prevPaciente) => ({
            ...prevPaciente,
            [name]: value
        }));
    };

    const handleDatePicker = (date) => {
        setPaciente((prevPaciente) => ({
            ...prevPaciente,
            FechaNacimiento: date
        }));
    };

    const handleObraSocial = (opc) => {
        if (opc.length > 0) {
            setPaciente((prevPaciente) => ({
                ...prevPaciente,
                Plan: opc[0].codigo,
                PlanText: opc[0].text
            }));
        }
    };

    const handleObraSocial2 = (opc) => {
        if (opc.length > 0) {
            setPaciente((prevPaciente) => ({
                ...prevPaciente,
                Plan2: opc[0].codigo,
                Plan2Text: opc[0].text
            }));
        }
    };

    const handleClearForm = () => {
        setPaciente(constante('emptyInputs'));
        setClear(true);
    };
    return (
        <div className="p-10">
            <h2 className="text-3xl flex justify-center my-5 text-principal font-bold"><p>Crear nuevo paciente</p></h2>
            <h2 className='text-xl my-5 text-secondary'>Datos personales</h2>
            <section id="section-validation" className="lg:grid grid-cols-3 gap-5">
                <InputText placeholder='Fernando' keyUpType="only-text" onChange={handleChange} ide="Nombre" name="Nombre" value={paciente.Nombre} label={constante('LABEL_NOMBRE_INPUT')}/>
                <InputText placeholder='Pérez' keyUpType="only-text" onChange={handleChange} ide="Apellido" name="Apellido" value={paciente.Apellido} label={constante('LABEL_APELLIDO_INPUT')}/>
                <InputText keyUpType="only-number" onChange={handleChange} ide="NumDocumento" name="NumDocumento" value={paciente.NumDocumento} label={constante('LABEL_DNI_INPUT')}/>
                <InputText keyUpType="only-number" onChange={handleChange} ide="Telefono" name="Telefono" value={paciente.Telefono} label={constante('LABEL_TELEFONO_INPUT')}/>
                <InputDatePicker disabledFuture={true} initialValue={paciente.FechaNacimiento} onChange={handleDatePicker} ide="FechaNacimiento" name="FechaNacimiento" value={paciente.FechaNacimiento} label={constante('LABEL_FNAC_INPUT')}/>
                <InputSelect onChange={handleChange} initialValue={paciente.Genero} data={SEXO} pars={{ label: 'Género *', ide: 'Genero' }}/>
                <InputText onChange={handleChange} ide="Mail" name="Mail" value={paciente.Mail} label={constante('LABEL_EMAIL_INPUT')}/>
                <InputText onChange={handleChange} ide="Direccion" name="Direccion" value={paciente.Direccion} label={constante('LABEL_DOMICILIO_INPUT')}/>
                <InputSelect onChange={handleChange} initialValue={paciente.ciudad} data={localidades} pars={{ label: 'Localidad', ide: 'CodPos' }}/>
            </section>
            <h2 className='text-xl my-5 text-secondary'>Obra social</h2>
            <section className="lg:grid grid-cols-3 gap-5">
                <AutoComplete initialValue={paciente.PlanText} clear={clear} onChange={handleObraSocial} data={obrasociales} label={constante('LABEL_OSPRINCIPAL_INPUT')} ide="PlanText" />
                <AutoComplete initialValue={paciente.Plan2Text} clear={clear} onChange={handleObraSocial2} data={obrasociales} label={constante('LABEL_OSSECUNDARIA_INPUT')} ide="Plan2Text" />
                <InputText onChange={handleChange} ide="NumAfiliado" name="NumAfiliado" value={paciente.NumAfiliado} label={constante('LABEL_NUM_AFILIADO_INPUT')}/>
            </section>
            <section>
                <TextArea onChange={handleChange} ide="Antecedentes" name="Antecedentes" value={paciente.Antecedentes} label={constante('LABEL_ANTECEDENTES_INPUT')} />
                <div className="flex justify-end self-end pe-3 gap-3 mt-10">
                    <Boton ide="clearButton" texto="Limpiar" color="bg-secondary hover:bg-gray-700" onClick={handleClearForm}></Boton>
                    <Boton ide="saveButton" texto="Guardar" color="bg-tradewind-500 hover:bg-tradewind-600" onClick={handleSave}></Boton>
                </div>
            </section>
        </div>
    );
}
