export const addPacienteConstants = {
    LABEL_NOMBRE_INPUT: 'Nombre *',
    LABEL_APELLIDO_INPUT: 'Apellido *',
    LABEL_TELEFONO_INPUT: 'Teléfono *',
    LABEL_SEXO_INPUT: 'Sexo *',
    LABEL_FNAC_INPUT: 'Fecha de nacimiento *',
    LABEL_EMAIL_INPUT: 'Email',
    LABEL_DNI_INPUT: 'DNI *',
    LABEL_DOMICILIO_INPUT: 'Domicilio *',
    LABEL_LOCALIDAD_INPUT: 'Localidad',
    LABEL_OSPRINCIPAL_INPUT: 'Obra social principal',
    LABEL_PLAN_INPUT: 'Plan',
    LABEL_OSSECUNDARIA_INPUT: 'Obra social secundaria',
    LABEL_NUM_AFILIADO_INPUT: 'Número de afiliado',
    LABEL_PROF_INPUT: 'Médico Cabecera',
    LABEL_ULTIMAVISITA_INPUT: 'Última visita',
    LABEL_ANTECEDENTES_INPUT: 'Antecedentes',
    VALID_INPUTS_PACIENT: [
        'Nombre',
        'Apellido',
        'Telefono',
        'FechaNacimiento',
        'Genero',
        'Direccion'
    ],
    emptyInputs: {
        Nombre: '',
        Apellido: '',
        FechaNacimiento: false,
        Genero: '',
        Direccion: '',
        CodPos: '',
        Telefono: '',
        Mail: '',
        TipoDocumento: 1,
        NumDocumento: '',
        Plan: '',
        Plan2: '',
        Empres: null,
        Antecedentes: ''
    }
};

export const SEXO = [
    {
        ide: 'femenino',
        text: 'Femenino'
    },
    {
        ide: 'masculino',
        text: 'Masculino'
    },
    {
        ide: 'otro_sexo',
        text: 'Otro'
    }
];

export const errors = {
    Nombre: 'El nombre es obligatorio',
    Apellido: 'El apellido es obligatorio',
    FechaNacimiento: 'La fecha de nacimiento es obligatoria',
    Genero: 'El género es obligatorio',
    Direccion: 'El domicilio es obligatorio',
    Telefono: 'El teléfono es obligatorio',
}
export const Popups = {
    OPCIONES_POPUP_PACIENTE: {
        title: 'Está a punto de añadir un nuevo paciente.',
        text: 'Desea continuar?'
    }
};

export const emptyTurn = {
    fecha: null,
    paciente_id: '',
    horario_id: '',
    observ: '',
    dni: '',
    obra_social: '',
    obra_social_text: ''
};
